import React, { useState, useEffect } from "react";
import { Upload } from "antd";
import {
	Icon,
	Container,
	Typography,
	IconButton,
	Select,
} from "@hegias/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
	UploadFile,
	RcCustomRequestOptions,
	RcFile,
} from "antd/lib/upload/interface";
import { useToaster } from "contexts/Toaster";
import { FILENAME } from "common/regex";
import {
	imageAccept,
	objectAccept,
	materialAccept,
	materialAcceptRef,
	ext,
} from "common/filetypes";
import { ItemKinds } from "common/itemKinds";

const { Dragger } = Upload;

/* const acceptedExtensions = [...imageAccept, ...objectAccept, ...materialAccept]
	.map((currExt: string) => `.${currExt}`)
	.join(); */

const acceptedExtensions: any = {
	[ItemKinds.Scene]: [
		...objectAccept,
		...materialAcceptRef,
		...materialAccept,
	]
		.map((currExt: string) => `.${currExt}`)
		.join(),
	[ItemKinds.Object]: [
		...objectAccept,
		...materialAcceptRef,
		...materialAccept,
	]
		.map((currExt: string) => `.${currExt}`)
		.join(),
	[ItemKinds.Material]: [...materialAcceptRef]
		.map((currExt: string) => `.${currExt}`)
		.join(),
};

interface HegiasDraggerProps {
	setFiles: (files: UploadFile[]) => void;
	setWarnings: (warnings: string[]) => void;
	setErrors: (errors: string[]) => void;
	materialsMap: { map: string; file: UploadFile }[];
	setMaterialsMap: (newList: { map: string; file: UploadFile }[]) => void;
	kind: keyof typeof ItemKinds;
	maxSize?: number;
}

const HegiasDragger = ({
	setFiles,
	setWarnings,
	setErrors,
	materialsMap,
	setMaterialsMap,
	kind,
	maxSize = 0,
}: HegiasDraggerProps) => {
	const { t } = useTranslation();

	const [currentWarnings, setCurrentWarnings] = useState<string[]>([]);
	const [currentErrors, setCurrentErrors] = useState<string[]>([]);

	const [currentFileList, setCurrentFileList] = useState<UploadFile[]>([]);

	const toaster = useToaster();

	const parseFileList = () => {
		let containsObj = false;
		let containsMtl = false;
		let containsGltf = false;
		let containsBin = false;
		let containsGlb = false;
		let containsIfc = false;

		/* 	let containDiffuseMap = false; */

		const objectFiles: UploadFile[] = [];
		const textureFiles: UploadFile[] = [];
		const materialFiles: UploadFile[] = [];

		currentFileList.forEach((file) => {
			const extension = ext(file.name);
			if (extension === "obj") {
				containsObj = true;
			}
			if (extension === "mtl") {
				containsMtl = true;
			}
			if (extension === "gltf") {
				containsGltf = true;
			}
			if (extension === "bin") {
				containsBin = true;
			}
			if (extension === "glb") {
				containsGlb = true;
			}
			if (extension === "ifc") {
				containsIfc = true;
			}

			if (
				kind !== ItemKinds.Material &&
				objectAccept.includes(extension)
			) {
				objectFiles.push(file);
			}

			if (
				kind !== ItemKinds.Material &&
				imageAccept.includes(extension)
			) {
				textureFiles.push(file);
			}

			if (
				kind === ItemKinds.Material &&
				materialAcceptRef.includes(extension)
			) {
				materialFiles.push(file);
			}
			/* if (materialAccept.includes(extension)) {
				materialFiles.push(file);
			} */
		});

		return {
			containsObj,
			containsMtl,
			containsGltf,
			containsIfc,
			containsBin,
			containsGlb,
			objectFiles,
			textureFiles,
			materialFiles,
		};
	};

	const checkDuplicateGeometryFile = (
		file: UploadFile,
		fileList: UploadFile[],
	) => {
		const dup = [
			...fileList.filter((item) => item.name !== file.name),
			file,
		].filter((item) => objectAccept.includes(ext(item.name)));

		return dup.length > 1;
	};

	const beforeUpload: (file: RcFile, fileList: RcFile[]) => boolean = (
		file,
		fileList,
	) => {
		if (file.size > maxSize && maxSize !== 0) {
			toaster.error({
				message: t("app.global.msg.title.note"),
				description: t("app.AntdDragger.msg.fileTooBig"),
			});
			setFiles(currentFileList);
			setCurrentFileList((currFileList) => [...currFileList]);
			return false;
		}

		if (
			checkDuplicateGeometryFile(file as RcFile, fileList) &&
			objectAccept.includes(ext(file.name))
		) {
			toaster.error({
				message: t("app.global.msg.title.note"),
				description: t("app.AntdDragger.msg.doubleGeometry"),
			});
			setFiles(currentFileList);
			setCurrentFileList((currFileList) => [...currFileList]);
			return false;
		}

		if (
			checkDuplicateGeometryFile(file, currentFileList) &&
			objectAccept.includes(ext(file.name))
		) {
			toaster.error({
				message: t("app.global.msg.title.note"),
				description: t("app.AntdDragger.msg.geometryAlreadyUploaded"),
			});
			setFiles(currentFileList);
			setCurrentFileList((currFileList) => [...currFileList]);

			return false;
		}

		if (!FILENAME.test(file.name)) {
			toaster.error({
				message: t("app.global.msg.title.note"),
				description: t("app.AntdDragger.msg.invalidFilename"),
			});
			setFiles(currentFileList);
			setCurrentFileList((currFileList) => [...currFileList]);

			return false;
		}

		setFiles([...currentFileList, file]);
		setCurrentFileList((currFileList) => [...currFileList, file]);
		return true;
	};

	const customRequest = (props: RcCustomRequestOptions) => {
		const { onSuccess, file } = props;
		return setTimeout(() => {
			replaceItem(file as RcFile);
			onSuccess({ status: "ok" }, file);
		}, 50);
	};

	const checkWarnings = ({
		containsObj,
		containsMtl,
		containsGltf,
		containsBin,
		containsGlb,
		containsIfc,
		objectFiles,
		textureFiles,
	}: // materialFiles,
	{
		containsObj: boolean;
		containsMtl: boolean;
		containsGltf: boolean;
		containsBin: boolean;
		containsGlb: boolean;
		containsIfc: boolean;
		objectFiles: UploadFile[];
		textureFiles: UploadFile[];
		materialFiles: UploadFile[];
	}) => {
		const listOfWarnings = [];

		if (containsObj && !containsMtl) {
			listOfWarnings.push("app.AntdDragger.warning.noMaterial");
		}
		if (containsGltf && !containsBin) {
			listOfWarnings.push("app.AntdDragger.warning.noBin");
		}
		if (
			!containsGlb &&
			objectFiles.length &&
			!textureFiles.length &&
			!containsIfc
		) {
			listOfWarnings.push("app.AntdDragger.warning.noTextures");
		}

		return listOfWarnings;
	};

	const checkErrors = ({
		containsObj,
		containsMtl,
		containsGltf,
		containsBin,
		// containsGlb,
		// containsIfc,
		objectFiles,
		textureFiles,
	}: // materialFiles,
	{
		containsObj: boolean;
		containsMtl: boolean;
		containsGltf: boolean;
		containsBin: boolean;
		containsGlb: boolean;
		containsIfc: boolean;
		objectFiles: UploadFile[];
		textureFiles: UploadFile[];
		materialFiles: UploadFile[];
	}) => {
		const listOfErrors = [];

		if (containsBin && !containsGltf) {
			listOfErrors.push("app.AntdDragger.error.noGltf");
		}
		if (containsMtl && !containsObj) {
			listOfErrors.push("app.AntdDragger.error.noObj");
		}
		if (textureFiles.length && !objectFiles.length) {
			listOfErrors.push("app.AntdDragger.error.noModel");
		}

		// Check if diffuse map is set.
		if (kind === ItemKinds.Material) {
			const diffuseMapExist = materialsMap.some(
				(item: { map: string; file: UploadFile }) =>
					item.map === "diffuse",
			);

			if (!diffuseMapExist) {
				listOfErrors.push("app.AntdDragger.error.diffuseRequired");
			}
		}

		// Check if all files are mapped.
		if (
			kind === ItemKinds.Material &&
			materialFiles.length !== 0 &&
			materialsMap.length !== materialFiles.length
		) {
			listOfErrors.push("app.AntdDragger.error.missingMap");
		}

		// Check if a map is already been used.
		if (kind === ItemKinds.Material && materialsMap.length !== 0) {
			const valuesToCheck = materialsMap.map((item) => item.map);

			const materialsMapDuplicated = valuesToCheck.filter(
				(item: any, index: any) => {
					return valuesToCheck.indexOf(item) !== index;
				},
			);

			if (materialsMapDuplicated.length !== 0) {
				listOfErrors.push("app.AntdDragger.error.mapUnique");
			}
		}

		setErrors(listOfErrors);

		return listOfErrors;
	};

	const replaceItem = (file: UploadFile) => {
		const index = currentFileList.indexOf(file);
		const newFileList = [...currentFileList];
		newFileList.splice(index, 1, file);
		setFiles(newFileList);
		setCurrentFileList(newFileList);
	};

	const onRemove = (file: UploadFile) => {
		const index = currentFileList.indexOf(file);
		const newFileList = [...currentFileList];
		newFileList.splice(index, 1);
		setFiles(newFileList);
		setCurrentFileList(newFileList);

		if (kind === ItemKinds.Material) {
			const newList = materialsMap.filter(
				(item: { map: string; file: UploadFile }) => {
					return item.file.uid !== file.uid;
				},
			);

			setMaterialsMap(newList);
		}
	};

	const getName: (filename: string) => string = (filename) => {
		return filename.split(".").slice(0, -1).join(".");
	};

	const materialMapOptions: Array<{
		label: string;
		value:
			| "thumbnail"
			| "diffuse"
			| "normal"
			| "ambientOcclusion"
			| "mapARM"
			| "emissive"
			| "roughness"
			| "transparency"
			| "bump"
			| "metalness";
	}> = React.useMemo(
		() => [
			{
				label: t("app.global.general.thumbnail"),
				value: "thumbnail",
			},
			{
				label: t("app.createMaterial.modal.mapDiffuse"),
				value: "diffuse",
			},
			{
				label: t("app.createMaterial.modal.mapNormal"),
				value: "normal",
			},
			{
				label: t("app.createMaterial.modal.mapMetalness"),
				value: "metalness",
			},
			{
				label: t("app.createMaterial.modal.mapEmissive"),
				value: "emissive",
			},
			{
				label: t("app.createMaterial.modal.mapRoughness"),
				value: "roughness",
			},
			{
				label: t("app.createMaterial.modal.mapTransparency"),
				value: "transparency",
			},
			{
				label: t("app.createMaterial.modal.mapAO"),
				value: "ambientOcclusion",
			},
			{
				label: t("app.createMaterial.modal.mapBump"),
				value: "bump",
			},
			/* {
				label: t("app.createMaterial.modal.mapARM"),
				value: "mapARM",
			}, */
		],
		[t],
	);

	const getIntensityForMap = (map: string) => {
		switch (map) {
			/* case "displacement": */
			case "bump":
			case "emissive":
			case "AO":
				return "1";
			case "metalness":
			case "roughness":
				return "1.0";
			case "transparency":
				return "0.99";
			default:
				return "1";
		}
	};

	const renderLine: (file: UploadFile) => React.ReactChild = (
		file: UploadFile,
	) => {
		return (
			<StyledItemRow key={file.name} kind={kind}>
				<Typography as="span">{getName(file.name)}</Typography>
				{kind === ItemKinds.Material && (
					<Select
						options={materialMapOptions}
						placeholder={
							// If only 1 file is uploaded, its a diffuse.
							currentFileList.length === 1
								? t("app.createMaterial.modal.mapDiffuse")
								: t("app.global.option.chooseOption")
						}
						onChange={({ target: { value: map } }) => {
							let newList: any;

							// Check if current file already have a map.
							const mapExist = materialsMap?.some(
								(item: { map: string; file: UploadFile }) =>
									item.file.uid === file.uid,
							);

							// If a map for that file does not exist.
							if (!mapExist) {
								newList = [
									...materialsMap,
									{
										map,
										file,
										intensity: getIntensityForMap(map),
									},
								];
							} else {
								// Update the current file.
								newList = materialsMap.map(
									(item: { map: string; file: UploadFile }) =>
										item.file.uid === file.uid
											? {
													map,
													file,
													intensity: getIntensityForMap(
														map,
													),
											  }
											: item,
								);
							}

							setMaterialsMap(newList);
						}}
					/>
				)}
				<Typography as="span">
					{ext(file.name).toUpperCase()}
				</Typography>
				<StyledLineEnd>
					{file.status === "done" ? (
						<Icon name="CorrectIcon" size="16px" color="primary" />
					) : (
						<Icon
							name="WarningIcon"
							size="16px"
							color="red"
							hoverColor="red"
						/>
					)}
					<IconButton size="mini" onClick={() => onRemove(file)}>
						<Icon
							name="DeleteIcon"
							size="50%"
							color="red"
							hoverColor="red"
						/>
					</IconButton>
				</StyledLineEnd>
			</StyledItemRow>
		);
	};

	const parsedFileList = parseFileList();

	const { objectFiles, textureFiles, materialFiles } = parsedFileList;

	useEffect(() => {
		const updatedParsedFileList = parseFileList();

		const listOfWarnings = checkWarnings(updatedParsedFileList);
		const listOfErrors = checkErrors(updatedParsedFileList);

		setCurrentWarnings(listOfWarnings);
		setCurrentErrors(listOfErrors);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFileList, materialsMap]);

	useEffect(() => {
		/* When the modal is closed and opend again, in case there was a material mapping,
		   delete it by setting an empty array. */
		if (kind === ItemKinds.Material && currentFileList.length === 0) {
			setMaterialsMap([]);
		}

		// If only 1 file is uploaded, set it his map as diffuse.
		if (kind === ItemKinds.Material && currentFileList.length === 1) {
			const newList = [{ map: "diffuse", file: currentFileList[0] }];
			setMaterialsMap(newList);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFileList]);

	useEffect(() => {
		setWarnings(currentWarnings);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentWarnings]);

	useEffect(() => {
		setErrors(currentErrors);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentErrors]);

	return (
		<StyledContainer divide={currentFileList.length > 0}>
			<div>
				<Typography as="h2" color="primary">
					{t("app.AntdDragger.modal.dragger.title")}
				</Typography>
				<StyledDragger
					accept={acceptedExtensions[kind]}
					multiple
					customRequest={customRequest}
					showUploadList={false}
					fileList={currentFileList}
					beforeUpload={beforeUpload}
				>
					<StyledDraggerContainer>
						<Icon name="FolderIcon" size="80px" color="grey700" />
						<Typography>
							{t("app.AntdDragger.modal.dragger.content")}
						</Typography>
						<StyledTagsContainer>
							{kind !== ItemKinds.Material && (
								<>
									{objectAccept.map((fileExtension) => (
										<StyledTags key={fileExtension}>
											{fileExtension === "obj"
												? "OBJ/MTL"
												: fileExtension.toUpperCase()}
										</StyledTags>
									))}
									{imageAccept.map((fileExtension) => (
										<StyledTags key={fileExtension}>
											{fileExtension.toUpperCase()}
										</StyledTags>
									))}
								</>
							)}

							{kind === ItemKinds.Material &&
								materialAcceptRef.map((fileExtension) => (
									<StyledTags key={fileExtension}>
										{fileExtension.toUpperCase()}
									</StyledTags>
								))}
						</StyledTagsContainer>
					</StyledDraggerContainer>
				</StyledDragger>
			</div>
			{(!!objectFiles.length ||
				!!materialFiles.length ||
				!!textureFiles.length) && (
				<StyledFilesCol>
					<StyledFileContainer>
						<Typography as="h2" color="primary">
							{t("app.AntdDragger.modal.dragger.uploadList")}{" "}
						</Typography>
					</StyledFileContainer>
					<StyledFileContainer>
						<StyledWarningRow>
							<ul>
								{currentWarnings.map((warning) => (
									<li key={warning}>{t(warning)}</li>
								))}
							</ul>
							<ul>
								{currentErrors.map((error) => (
									<li key={error}>{t(error)}</li>
								))}
							</ul>
						</StyledWarningRow>
						{/* {printWarning(parsedFileList)} */}
						{objectFiles.map((file) => renderLine(file))}
						{textureFiles.map((file) => renderLine(file))}
						{materialFiles.map((file) => renderLine(file))}
					</StyledFileContainer>
				</StyledFilesCol>
			)}
		</StyledContainer>
	);
};

const StyledDragger = styled(Dragger)`
	span.ant-upload.ant-upload-btn {
		padding: 0;
	}
	div.ant-upload.ant-upload-drag {
		border: 1px solid red;
		background-color: red;
	}
`;

const StyledContainer = styled.div<{ divide: boolean }>`
	display: grid;
	grid-template-columns: ${({ divide }) => (divide ? "40% 60%" : "100% 0%")};
`;

const StyledTagsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 385px;
	margin: 10px;
`;

const StyledTags = styled.div`
	background-color: ${({ theme }) => theme.colors.grey300};
	color: ${({ theme }) => theme.colors.grey700};
	font-weight: ${({ theme }) => theme.typography.weight.normal};
	padding: 2px 5px;
	margin: 2px 5px;
	border-radius: 4px;
`;

const StyledDraggerContainer = styled(Container)`
	height: 30vh;
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.white};
	/* border: 1px solid red; */
`;

const StyledFilesCol = styled.div`
	flex: 1;
	width: 100%;
	padding: 0 0 0 20px;
`;

const StyledFileContainer = styled.div`
	margin-top: 8px;
`;

const StyledItemRow = styled.div<{ kind: string }>`
	display: grid;
	padding: 5px 10px;
	border-radius: 4px;
	width: 100%;
	${({ kind }) =>
		kind === ItemKinds.Material &&
		`
    grid-gap: 10px;
  `}
	grid-template-columns: ${({ kind }) =>
		kind === ItemKinds.Material ? "35% 35% 10% 15%" : "70% 15% 15%"};
	align-items: center;

	&:nth-child(even) {
		background: ${({ theme }) => theme.colors.grey200};
	}
	&:nth-child(odd) {
		background: ${({ theme }) => theme.colors.white};
	}
`;

const StyledLineEnd = styled.div`
	display: flex;
	justify-content: space-between;
	height: 100%;
	align-items: center;
`;

const StyledWarningRow = styled.div`
	padding-bottom: 10px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
	ul {
		li {
			list-style-type: none;
		}
		&:first-child {
			li {
				color: ${({ theme }) => theme.colors.orange};
			}
		}
		&:last-child {
			li {
				color: ${({ theme }) => theme.colors.red};
			}
		}
	}
`;

export default HegiasDragger;
